@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  width: 100vw;
  height: 100vh;
  outline: none;
  margin: 0;
  padding: 0;
  background: #181e33;
  font-family: 'Montserrat', sans-serif;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
